import React from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";

export default function FooterPlaceholder() {
  return (
    <div className="px-4 py-5 mt-2" style={{ background: "#E0E0E0" }}>
      <Container
        className="pt-5"
        style={{ maxWidth: 1200, margin: "auto", background: "#E0E0E0" }}
      >
        <Row>
          <Col md={4}>
            <Container>
              <Row>
                <Placeholder as="div" animation="glow" className="w-100">
                  <Placeholder style={{ height: 88, width: 150 }} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder
                  as="h5"
                  animation="glow"
                  className="text-light fw-light mt-4"
                >
                  <Placeholder xs={12} />
                </Placeholder>
              </Row>
            </Container>
          </Col>

          <Col sm={6} md={{ span: 2, offset: 2 }}>
            <Container>
              <Row className="pb-3">
                <Placeholder as="h5" animation="glow" className="fw-light mt-4">
                  <Placeholder xs={8} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder
                  as="a"
                  className="py-2 text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder
                  as="a"
                  className="py-2 text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder
                  as="a"
                  className="py-2 text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder
                  as="a"
                  className="py-2 text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Row>
            </Container>
          </Col>

          <Col sm={6} md={4}>
            <Container>
              <Row className="pb-3">
                <Placeholder as="h5" animation="glow" className="fw-light mt-4">
                  <Placeholder xs={6} />
                </Placeholder>
              </Row>
              <Row>
                <Placeholder as="p" animation="glow" className="text-light">
                  <Placeholder xs={8} />
                </Placeholder>
              </Row>
              <Row className="px-0">
                <Placeholder as="div" className="w-100" animation="glow">
                  <Placeholder xs={12} style={{ height: 40 }} />
                </Placeholder>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row className="py-4">
          <Col md={3}>
            <div style={{ height: 1, width: "100%", background: "#fff" }}></div>
          </Col>
          <Col md={6}>
            <Container>
              <Placeholder as="div" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </Container>
          </Col>
          <Col md={3}>
            <div style={{ height: 1, width: "100%", background: "#fff" }}></div>
          </Col>
        </Row>

        <Row className="py-3">
          <Col sm={12} md={4}>
            <Placeholder
              as="h5"
              animation="glow"
              className="text-light fw-light mt-4"
            >
              <Placeholder xs={6} />
            </Placeholder>
          </Col>
          <Col sm={12} md={{ span: 3, offset: 5 }}>
            <Row>
              <Col className="mt-4" sm={12} md={6}>
                <Placeholder
                  as="a"
                  animation="glow"
                  className="text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Col>
              <Col className="mt-4" sm={12} md={6}>
                <Placeholder
                  as="a"
                  animation="glow"
                  className="text-decoration-none text-light"
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
