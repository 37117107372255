import React from "react";
import Product_Vector from "../../../../assets/media/Product_Vector.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { ISolutions } from "../../../../models/blocks/Solutions";
import SolutionCard from "./SolutionCard";
import SolutionSubCard from "./SolutionSubCard";
import "./styles.scss";

interface Props {
  data: ISolutions;
}

export default function Solutions({ data }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <div
        className={`products ${isMobile ? "py-5" : "py-0"}`}
        style={{
          backgroundImage: `url(${Product_Vector})`,
        }}
      >
        <div className="products-container">
          <div className={`mb-5`}>
            <h4
              className={`display-4 text-light text-center fw-medium ${
                isMobile ? "pt-5" : "py-0"
              }`}
              style={{ color: "red" }}
            >
              {data.title}
            </h4>
            <p className="text-center text-light">{data.description}</p>
          </div>
          <SolutionCard solutionCards={data.solutionCards} />
        </div>
      </div>
      <SolutionSubCard solutionSubCards={data.solutionSubCards} />
    </>
  );
}
