import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import NavigationPlaceholder from "../components/Navigation/Placeholder";
import FooterPlaceholder from "../components/Footer/Placeholder";
import { Layout } from "../models/Layout";
import ScrollToTop from "../common/scrollToTop";

export default function Root() {
  const [layoutData, setLayoutData] = useState<Layout | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if data is already in localStorage
        const cachedData = localStorage.getItem("layoutData");
        if (cachedData) {
          // If cached data exists, use it
          setLayoutData(JSON.parse(cachedData));
        } else {
          // Fetch data from JSON file if not cached
          const response = await fetch("assets/data/layout.json");
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setLayoutData(data);

          // Store the fetched data in localStorage for future use
          localStorage.setItem("layoutData", JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching the JSON data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {layoutData && loading ? (
        <NavigationPlaceholder />
      ) : (
        <Navigation navigation={layoutData?.navigation ?? null} />
      )}
      <ScrollToTop />
      <Outlet />
      {loading ? (
        <FooterPlaceholder />
      ) : (
        <Footer footer={layoutData?.footer ?? null} />
      )}
    </>
  );
}
