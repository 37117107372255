import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import useMediaQuery from "../../hooks/useMediaQuery";

interface Props extends ButtonProps {
  background: string;
  color: string;
  size?: "sm" | "lg" | undefined;
  variant?: string;
  children: React.ReactNode;
}

export default function CustomButton({
  background,
  color,
  variant,
  children,
  size,
  ...rest
}: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Button
      {...rest}
      size={size}
      className={`${isMobile ? "px-4" : "px-5"}`}
      style={{
        background,
        color,
        border: "none",
      }}
    >
      {children}
    </Button>
  );
}
