import { useEffect, useState } from "react";

// Custom hook to handle media query
function useMediaQuery(query: string) {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    // Function to update the state when the media query changes
    const updateMatches = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    // Set the initial value of matches
    setMatches(mediaQueryList.matches);

    // Add event listener for changes to the media query
    mediaQueryList.addEventListener("change", updateMatches);

    // Cleanup the event listener on unmount
    return () => {
      mediaQueryList.removeEventListener("change", updateMatches);
    };
  }, [query]);

  return matches;
}

export default useMediaQuery;
