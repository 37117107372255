import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import useMediaQuery from "../../hooks/useMediaQuery";
import { IFooter } from "../../models/blocks/Footer";
import "./style.scss";

interface Props {
  footer: IFooter | null;
}

export default function Footer({ footer }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  if (!footer) return null;
  return (
    <div className="footer px-4 py-5">
      <Container className="footer-container pt-5">
        <Row>
          <Col md={4}>
            <Container>
              <Row>
                <Image fluid src={footer.logo.url} height={88} />
              </Row>
              <Row>
                <h5 className="text-left text-light fw-light mt-4">
                  {footer.footerNote}
                </h5>
              </Row>
            </Container>
          </Col>
          <Col sm={6} md={{ span: 2, offset: 2 }}>
            <Container>
              <Row className="pb-3">
                <h5
                  className="text-left fw-light mt-4"
                  style={{ color: "#49C0B6" }}
                >
                  USEFUL LINKS
                </h5>
              </Row>
              {footer.usefulLinks.map((link) => {
                return (
                  <Row key={link.id}>
                    <a
                      className="py-2 text-decoration-none text-light"
                      href="https://example.com"
                    >
                      {link.title}
                    </a>
                  </Row>
                );
              })}
            </Container>
          </Col>
          <Col sm={6} md={4}>
            <Container>
              <Row className="pb-3">
                <h5
                  className="text-left fw-light mt-4"
                  style={{ color: "#49C0B6" }}
                >
                  SUBSCRIBE
                </h5>
              </Row>
              <Row>
                <p className="text-light">
                  Don't miss to subscribe our news, kindly fill the form below.
                </p>
              </Row>
              <Row className="px-0">
                <div className="email-container">
                  <input
                    type="email"
                    placeholder="Your Email Here"
                    className="email-input"
                  />
                  <button className="email-button">
                    <span>&#8250;</span>
                  </button>
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={3}>
            <div className="footer-horizontal-line"></div>
          </Col>
          <Col className={`${isMobile ? "pt-4" : "pt-0"}`} md={6}>
            <Container>
              <Row align="center">
                {footer.social.map((social) => {
                  return (
                    <Col key={social.id}>
                      <a
                        className="footer-social-lnk"
                        href="https://example.com"
                      >
                        <Image
                          className="footer-social-lnk-icon"
                          src={social.socialIcon.url}
                          alt="social"
                        />
                      </a>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
          <Col md={3}>
            <div className="footer-horizontal-line"></div>
          </Col>
        </Row>
        <Row className="py-3">
          <Col sm={12} md={4}>
            <h5 className="text-left text-light fw-light mt-4">
              © 2024 All Rights Reserved
            </h5>
          </Col>
          <Col sm={12} md={{ span: 3, offset: 5 }}>
            <Row>
              <Col className="mt-4" sm={12} md={6}>
                <a
                  className="text-decoration-none text-light"
                  href="https://example.com"
                >
                  Privacy Policy
                </a>
              </Col>
              <Col className="mt-4" sm={12} md={6}>
                <a
                  className="text-decoration-none text-light"
                  href="https://example.com"
                >
                  Terms of Use
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
