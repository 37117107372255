import React from "react";
import { Navbar, Placeholder } from "react-bootstrap";

export default function NavigationPlaceholder() {
  return (
    <Navbar className="bg-light" expand="lg">
      <Navbar.Brand>
        <Placeholder
          as="div"
          animation="glow"
          className="me-2"
          style={{ width: "150px", height: "40px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Placeholder
          as="div"
          animation="glow"
          style={{ width: "100%", height: "40px" }}
        />
      </Navbar.Collapse>
    </Navbar>
  );
}
