import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import Root from "./pages/Root";
import { Pages } from "./models/Pages";
import { Spinner } from "react-bootstrap";
import CustomersPage from "./pages/Customers";
import ProductsPage from "./pages/Products";
import TeamPage from "./pages/Team";
import ContactPage from "./pages/Contact";

export default function App() {
  const [pagesData, setPagesData] = useState<Pages | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPagesData = async () => {
      try {
        // const response = await fetch("assets/data/pages.json");
        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }

        // const data = await response.json();
        // setPagesData(data);
        const cachedData = localStorage.getItem("pagesData");

        if (cachedData) {
          // Use cached data if available
          setPagesData(JSON.parse(cachedData));
        } else {
          // Fetch data if not cached
          const response = await fetch("assets/data/pages.json");
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setPagesData(data);
          // Cache the fetched data for future use
          localStorage.setItem("pagesData", JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching the JSON data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPagesData();
  }, []);
  console.log(pagesData);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          index: true,
          element: loading ? (
            <div>
              <Spinner animation="border" />
            </div> // Show a loading state
          ) : pagesData?.home ? (
            <HomePage
              isLandingPage={pagesData.home.isLandingPage}
              home={pagesData.home.data}
            />
          ) : (
            <ErrorPage /> // Fallback in case home data is missing
          ),
        },
        {
          path: "/products",
          element: loading ? (
            <div>
              <Spinner animation="border" />
            </div> // Show a loading state
          ) : pagesData?.home ? (
            <ProductsPage
              isLandingPage={pagesData.customer.isLandingPage}
              products={pagesData.products.data}
            />
          ) : (
            <ErrorPage /> // Fallback in case home data is missing
          ),
        },
        {
          path: "/customers",
          element: loading ? (
            <div>
              <Spinner animation="border" />
            </div> // Show a loading state
          ) : pagesData?.home ? (
            <CustomersPage
              isLandingPage={pagesData.customer.isLandingPage}
              customer={pagesData.customer.data}
            />
          ) : (
            <ErrorPage /> // Fallback in case home data is missing
          ),
        },
        {
          path: "/team",
          element: loading ? (
            <div>
              <Spinner animation="border" />
            </div> // Show a loading state
          ) : pagesData?.home ? (
            <TeamPage
              isLandingPage={pagesData.team.isLandingPage}
              team={pagesData.team.data}
            />
          ) : (
            <ErrorPage /> // Fallback in case home data is missing
          ),
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
        {
          path: "*",
          element: <ErrorPage />, // Catch-all route for undefined routes (404)
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
