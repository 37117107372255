import React from "react";
import "./styles.scss";
import { ISuccessStories } from "../../../models/blocks/SuccessStories";
import CustomButton from "../../../common/CustomButton";
interface Props {
  data: ISuccessStories;
}
export default function CaseStudies({ data }: Props) {
  return (
    <section className="section-row casestudies-section">
      <div className="section-inner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="title-section pb-5">
                <h4 className="display-4 text-center fw-medium">
                  {data.title}
                </h4>
                <p className="text-center">{data.description}</p>
              </div>
            </div>
          </div>
          {data.caseStudies.map((story) => {
            return (
              <div className="row casestudies-row">
                <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-first">
                  <div className="casestudies-img">
                    <img
                      className="img-responsive"
                      src={story.image.url}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-last">
                  <div className="casestudies-col">
                    <div className="casestudies-in">
                      <div className="casestudies-text">
                        <h5 className="text-left">
                          {story.title?.toUpperCase()}
                        </h5>
                        <p className="fw-light">{story.description}</p>
                      </div>
                      <div className="site-btn casestudies-btn">
                        {story.button && (
                          <CustomButton
                            href={story.button.link}
                            background={story.button.backgroundColor.hex}
                            color={story.button.textColor.hex}
                            size={story.button.size ? "sm" : "lg"}
                          >
                            {story.button.title}
                          </CustomButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
