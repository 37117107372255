import React from "react";

const ErrorPage = () => {
  return (
    <div className="container text-center">
      <h1>Oops!</h1>
      <p>Sorry, the page you are looking for cannot be found.</p>
    </div>
  );
};

export default ErrorPage;
