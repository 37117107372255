import React from "react";
import "./styles.scss";
import { IClients } from "../../../models/blocks/Client";
interface Props {
  data: IClients;
}
export default function Clients({ data }: Props) {
  return (
    <section className="section-row ourcustomers-section">
      <div className="section-inner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="title-section pb-5">
                <h4 className="display-4 text-center fw-medium">
                  {data.title.toUpperCase()}
                </h4>
                <p className="text-center">{data.description}</p>
              </div>
            </div>
          </div>

          <div className="row ourcustomers-row">
            {data.clients.map((client) => {
              return (
                <div className="col-md-2 col-sm-2 col-xs-12">
                  <div className="customer-img">
                    <img className="img-responsive" src={client.url} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
