import React from "react";
import Jumbotron from "../../components/Banner";
import { Team } from "../../models/Pages";
import "./styles.scss";
import CustomButton from "../../common/CustomButton";
interface Props {
  team: Team;
  isLandingPage: boolean;
}
export default function TeamPage({ team, isLandingPage }: Props) {
  return (
    <>
      <Jumbotron isLandingPage={isLandingPage} data={team.jumbotron} />
      <div className="site-content-container pt-5 px-4">
        <div className="page-wrap py-5">
          <section className="section-row ourteam-section">
            <div className="section-inner">
              <div className="container">
                <div className="row founder-row">
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="founder-img">
                      <img
                        className="img-responsive"
                        src="https://old.xinperformance.com/wp-content/uploads/2020/08/Faheem-Headshot.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-8 col-xs-12">
                    <div className="founder-text">
                      <h5 className="text-left">A Message from our Founder</h5>
                      <p className="fw-light">
                        Thank you for visiting! We hope that you find your time
                        here insightful.
                      </p>
                      <p className="fw-light">
                        At Xin Performance, our goal is to support Hotels and
                        Integrated Casino resorts establish and optimise the
                        building blocks for operational success. Having worked
                        in hotels for over 15 years, we understand the
                        complexities and the positive challenges that a
                        multi-cultural, service driven operation entails. We
                        dedicate ourselves in providing intelligent insight, the
                        best possible technology tools, together with meaningful
                        education through real-life case studies and our Xin
                        Academy. Everything on offer at Xin Performance is
                        centered around our core objective to connect each
                        person in your Hotel or Resort to values that you
                        uphold. Business values must translate into an
                        operational habit. Our team, with our products will
                        strive to ensure you are successful in living this out
                        everyday.
                      </p>
                      <p className="fw-light">
                        Please send us a note through our connect form for any
                        questions you may have. We are keen to learn about your
                        goals.
                      </p>
                      <p className="fw-light">
                        Thank you for your time. We hope to have the privilege
                        of joining your journey.
                      </p>
                      <p className="fw-light">
                        Sincerely,
                        <br />
                        <img
                          decoding="async"
                          className="alignnone size-full wp-image-899"
                          src="https://old.xinperformance.com/wp-content/uploads/2020/05/F_sign.png"
                          alt=""
                          width="153"
                          height="64"
                        />
                        <br />
                        Faheem Ebrahim
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section className="section-row casestudies-section countryteam-section">
        <div className="section-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="title-section pb-5">
                  <h4 className="display-4 text-center fw-medium">
                    Our People
                  </h4>
                  <p className="text-center">
                    A team rich with culture and diversity
                  </p>
                </div>
              </div>
            </div>
            <div className="row casestudies-row">
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-first">
                <div className="casestudies-img">
                  <img
                    className="img-responsive"
                    src="https://old.xinperformance.com/wp-content/uploads/2020/04/Hongkong-new.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-last">
                <div className="casestudies-col">
                  <div className="casestudies-in">
                    <div className="casestudies-text">
                      <h5 className="text-left">Hong Kong - Headquarters</h5>
                      <p className="fw-light">
                        This is our hometown and where we stem from. Though we
                        are now spread through multiple countries, this is where
                        it all began for us.
                      </p>
                    </div>
                    <div className="site-btn casestudies-btn">
                      <CustomButton
                        href="/products"
                        background="rgb(73, 192, 182)"
                        color="white"
                        size="lg"
                      >
                        Contact us
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row casestudies-row">
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-first">
                <div className="casestudies-img">
                  <img
                    className="img-responsive"
                    src="https://old.xinperformance.com/wp-content/uploads/2020/04/Thailand-new.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-last">
                <div className="casestudies-col">
                  <div className="casestudies-in">
                    <div className="casestudies-text">
                      <h5 className="text-left">
                        Thailand - Operations &amp; Sales
                      </h5>
                      <p className="fw-light">
                        The Land of Smiles is where we host our core operations.{" "}
                      </p>
                    </div>
                    <div className="site-btn casestudies-btn">
                      <CustomButton
                        href="/products"
                        background="rgb(73, 192, 182)"
                        color="white"
                        size="lg"
                      >
                        Contact us
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row casestudies-row">
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-first">
                <div className="casestudies-img">
                  <img
                    className="img-responsive"
                    src="https://old.xinperformance.com/wp-content/uploads/2020/04/Philipines.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-last">
                <div className="casestudies-col">
                  <div className="casestudies-in">
                    <div className="casestudies-text">
                      <h5 className="text-left">
                        Philippines - Client Support
                      </h5>
                      <p className="fw-light">
                        Our skilled support crew provides our clients with round
                        the clock support. They love to extend a helping hand.
                      </p>
                    </div>
                    <div className="site-btn casestudies-btn">
                      <CustomButton
                        href="/products"
                        background="rgb(73, 192, 182)"
                        color="white"
                        size="lg"
                      >
                        Contact us
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row casestudies-row">
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-first">
                <div className="casestudies-img">
                  <img
                    className="img-responsive"
                    src="https://old.xinperformance.com/wp-content/uploads/2020/04/India-new.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 casestudiescol-last">
                <div className="casestudies-col">
                  <div className="casestudies-in">
                    <div className="casestudies-text">
                      <h5 className="text-left">India - Technology</h5>
                      <p className="fw-light">
                        Our team of skilled engineers who keep the Xin
                        Performance engine running are based in Indore, India's
                        cleanest city
                      </p>
                    </div>
                    <div className="site-btn casestudies-btn">
                      <CustomButton
                        href="/products"
                        background="rgb(73, 192, 182)"
                        color="white"
                        size="lg"
                      >
                        Contact us
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
