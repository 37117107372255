import React from "react";
import Jumbotron from "../../components/Banner";
import "./styles.scss";
import { Customer } from "../../models/Pages";
import Clients from "./Clients";
import CaseStudies from "./CaseStudies";
interface Props {
  customer: Customer;
  isLandingPage: boolean;
}
export default function CustomersPage({ customer, isLandingPage }: Props) {
  return (
    <>
      <Jumbotron isLandingPage={isLandingPage} data={customer.jumbotron} />
      <div className="site-content-container pt-5 px-4">
        <div className="page-wrap py-5">
          <Clients data={customer.clients} />
          <CaseStudies data={customer.successStories} />
        </div>
      </div>
    </>
  );
}
