import React from "react";
import { IBrandPillars } from "../../../../models/blocks/BrandPillars";
import "./styles.scss";
interface Props {
  data: IBrandPillars;
}
export default function BrandPillars({ data }: Props) {
  return (
    <div className="pillars py-5">
      <h4 className="display-4 py-5 text-center fw-medium">{data.title}</h4>
      <div className="pillars-container px-4">
        {data.pillarCards
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((pillar) => (
            <div
              key={pillar.id}
              className="pillars-container-card p-2"
              style={{
                background: pillar.backgroundColor.hex,
              }}
            >
              <div
                className="pillars-container-card-topImage mb-4"
                style={{
                  backgroundImage: `url(${pillar.image.url})`,
                }}
              ></div>
              <div className="px-5">
                <h4 className="text-center text-light">{pillar.title}</h4>
                <p className="text-center text-light">{pillar.description}</p>
              </div>
              {pillar.icon && (
                <div className="pillars-container-card-icon align-self-center">
                  <img
                    src={pillar.icon.url}
                    alt="Ellipse"
                    width="auto"
                    height={30}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
