import React from "react";
import BrandPillars from "./components/BrandPillars";
import Solutions from "./components/Solutions";
import SuccessStories from "./components/SuccessStories";
import { Home } from "../../models/Pages";
import Jumbotron from "../../components/Banner";

interface Props {
  home: Home;
  isLandingPage: boolean;
}
export default function HomePage({ home, isLandingPage }: Props) {
  return (
    <>
      <Jumbotron isLandingPage={isLandingPage} data={home.jumbotron} />
      <BrandPillars data={home.brandPillars} />
      <SuccessStories data={home.successStories} />
      <Solutions data={home.solutions} />
    </>
  );
}
