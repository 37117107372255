import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CustomButton from "../../common/CustomButton";
import { INavigation } from "../../models/blocks/Navigation";
import "./styles.scss";

interface Props {
  navigation: INavigation | null;
}

export default function Navigation({ navigation }: Props) {
  if (!navigation) return null;
  return (
    <Navbar collapseOnSelect sticky="top" expand="lg" className="bg-light">
      <Container>
        <Navbar.Brand href="/">
          <Image className="me-2" src={navigation.logo.url} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {navigation.navigationLinks.length > 0 &&
              navigation.navigationLinks
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((item) => (
                  <NavLink
                    key={item.id}
                    to={item.link}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    {item.title.toUpperCase()}
                  </NavLink>
                ))}
          </Nav>
          <Nav>
            {navigation.button && (
              <CustomButton
                background={navigation.button.backgroundColor.hex}
                color={navigation.button.textColor.hex}
                href={navigation.button.link}
              >
                {navigation.button.title}
              </CustomButton>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
