import React from "react";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import { ISolutionSubCard } from "../../../../../models/blocks/Solutions";
import "./styles.scss";
interface Props {
  solutionSubCards: ISolutionSubCard[];
}

export default function SolutionSubCard({ solutionSubCards }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="rewards px-4 py-5">
      {solutionSubCards.map((product, index) => {
        return (
          <div
            key={product.id}
            style={{
              borderRight: "1px solid #8F9196",
              ...(isMobile && {
                borderRight: "none",
                borderBottom: "1px solid #8F9196",
                ...(index === solutionSubCards.length - 1 && {
                  borderBottom: "none",
                }),
              }),
              ...(index === solutionSubCards.length - 1 && {
                borderRight: "none",
              }),
            }}
          >
            <h4
              className="text-center px-5 pt-4"
              style={{
                visibility: "hidden",
                ...(product.winner && { visibility: "visible" }),
              }}
            >
              WINNER
            </h4>
            <div
              className="company-logo"
              style={{
                backgroundImage: `url(${product.image.url})`,
              }}
            ></div>
            <div>
              <h4 className="text-center px-5 pt-4 pb-4">{product.title}</h4>
            </div>
          </div>
        );
      })}
    </div>
  );
}
