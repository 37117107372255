import React from "react";
import Shape from "../../../../assets/media/Vector.svg";
import CustomButton from "../../../../common/CustomButton";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { ISuccessStories } from "../../../../models/blocks/SuccessStories";
import "./style.scss";
import { NavLink } from "react-router-dom";
interface Props {
  data: ISuccessStories;
}
export default function SuccessStories({ data }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className={`track-records ${isMobile ? "py-5" : "py-0"}`}
      style={{
        backgroundImage: `url(${Shape})`,
      }}
    >
      <div className="track-records-container">
        <h4
          className={`display-4 py-5 text-center fw-medium ${
            isMobile ? "py-5" : "pt-0"
          }`}
          style={{ color: "#49C0B6" }}
        >
          {data.title}
        </h4>
        <div className="track-records-cards-container px-4">
          {data.caseStudies.slice(0, 6).map((study) => {
            return (
              <div
                key={study.id}
                className="track-records-card px-3 text-center"
              >
                <img src={study.image.url} alt="logo" height={80} />
                <p className="text-center">{study.description}</p>
                <div className="track-records-card-button-container">
                  <NavLink to={study.button.link}>
                    <CustomButton
                      href={study.button.link}
                      background={study.button.backgroundColor.hex}
                      color={study.button.textColor.hex}
                    >
                      {study.button.title}
                    </CustomButton>
                  </NavLink>
                </div>
              </div>
            );
          })}
        </div>
        <div className="px-4 mt-4 text-end">
          <NavLink className="text-decoration-none" to="/customers">
            <p className="text-light">View all case studies</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
