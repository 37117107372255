import React from "react";
import Jumbotron from "../../components/Banner";
import { Products } from "../../models/Pages";
import "./styles.scss";
import { Tab, Nav } from "react-bootstrap";
import CustomButton from "../../common/CustomButton";
interface Props {
  products: Products;
  isLandingPage: boolean;
}
export default function ProductsPage({ products, isLandingPage }: Props) {
  return (
    <>
      <Jumbotron isLandingPage={isLandingPage} data={products.jumbotron} />
      <div className="site-content-container pt-5 px-4">
        <div className="page-wrap pt-5">
          <section className="section-row products-section">
            <div className="section-inner">
              <Tab.Container defaultActiveKey="QTX">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <Nav justify variant="tabs" className="products-tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="QTX">QTX</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="SPATX">SPATX</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="CUSTOM">CUSTOM</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="col-sm-12">
                      <Tab.Content>
                        <Tab.Pane eventKey="QTX">
                          <div className="container ourservices-container py-5">
                            <div className="row ourservices-row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="aboutbox">
                                  <div className="aboutbox-col aboutbox-img">
                                    <div className="aboutboximg-in">
                                      <img
                                        className="img-responsive"
                                        src="https://www.datocms-assets.com/142763/1729600462-service-qtx_big.png"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="aboutbox-col aboutbox-text">
                                    <div className="aboutboxtext-in">
                                      <h2>
                                        SERVICE QUALITY &amp; PROCESS
                                        OPTIMIZATION
                                      </h2>
                                      <p>
                                        The QTX primary objective is to help
                                        hotels build a direct link between core
                                        values and the teams that carry them out
                                        each day. With accessible yet robust
                                        technology, QTX is a complete solution
                                        for optimizing service quality within a
                                        hotel.
                                      </p>
                                      <div className="site-btn">
                                        <CustomButton
                                          href="/products"
                                          background="rgb(73, 192, 182)"
                                          color="white"
                                          size="lg"
                                        >
                                          Request a Demo
                                        </CustomButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <section className="section-row osmiddle-section">
                            <div className="section-inner">
                              <div className="container">
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          QTX PERFORM
                                        </h5>
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="1391"
                                                height="738"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/06/Screen-Shot-2020-04-28-at-11.57.53-AM.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/06/Screen-Shot-2020-04-28-at-11.57.53-AM.png 1391w, https://old.xinperformance.com/wp-content/uploads/2020/06/Screen-Shot-2020-04-28-at-11.57.53-AM-300x159.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/06/Screen-Shot-2020-04-28-at-11.57.53-AM-1024x543.png 1024w, https://old.xinperformance.com/wp-content/uploads/2020/06/Screen-Shot-2020-04-28-at-11.57.53-AM-768x407.png 768w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                        <div className="vc_btn3-container vc_btn3-inline mt-4">
                                          <CustomButton
                                            href="https://old.xinperformance.com/wp-content/uploads/2020/06/Introduction-to-QTX-Perform.pdf"
                                            background="rgb(73, 192, 182)"
                                            color="white"
                                            size="lg"
                                          >
                                            QTX perform Deck
                                          </CustomButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          SELF ASSESSMENTS FOR HIGH PERFORMING
                                          HOTELS
                                        </h5>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              Motion creates Emotion. In a
                                              Hotel, this is a critical concept.
                                              The only way to differentiate your
                                              service actions from competitors
                                              is to consistently take action
                                              that is linked directly to your
                                              company’s core values.
                                              <a href="https://old.xinperformance.com/qtx-perform/">
                                                <strong>QTX Perform</strong>
                                              </a>
                                              is built to serve this purpose. A
                                              flexible, fully serviced
                                              technology that allows a hotel
                                              team to customize questions that
                                              represent the values and beliefs
                                              that set them apart. With
                                              unlimited testing capabilities,{" "}
                                              <strong>
                                                <a href="https://old.xinperformance.com/qtx-perform/">
                                                  QTX Perform
                                                </a>{" "}
                                              </strong>
                                              acts as a catalyst for hotels in
                                              creating powerful, effortless
                                              action that is tracked in
                                              real-time and organized to clearly
                                              and dynamically evaluate a teams
                                              strengths and weaknesses.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          QTX QUALIFY
                                        </h5>
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="1419"
                                                height="892"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/06/QTX_Qualify-2.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/06/QTX_Qualify-2.png 1419w, https://old.xinperformance.com/wp-content/uploads/2020/06/QTX_Qualify-2-300x189.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/06/QTX_Qualify-2-1024x644.png 1024w, https://old.xinperformance.com/wp-content/uploads/2020/06/QTX_Qualify-2-768x483.png 768w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                        <div className="vc_btn3-container vc_btn3-inline mt-4">
                                          <CustomButton
                                            href="https://old.xinperformance.com/wp-content/uploads/2020/06/Introduction-to-QTX-Qualify.pdf"
                                            background="rgb(73, 192, 182)"
                                            color="white"
                                            size="lg"
                                          >
                                            QTX Qualify Deck
                                          </CustomButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          MYSTERY SHOPPING THAT WORKS FOR YOU
                                        </h5>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              If you are frustrated that Mystery
                                              Shopping programmes don’t actually
                                              work for you Hotel, you are not
                                              alone. We believe hotels should
                                              craft their service experience in
                                              line with their core values. We
                                              believe each operation should have
                                              the flexibility to deliver the
                                              standards important to them. We
                                              believe mystery shopping should be
                                              done much more frequently and that
                                              it is a learning process, NOT a
                                              judgement.&nbsp;
                                              <strong>
                                                <a href="https://old.xinperformance.com/qtx-qualify/">
                                                  QTX Qualify
                                                </a>{" "}
                                              </strong>
                                              is a platform that enables a Hotel
                                              to craft the service experience in
                                              a way that represent them exactly
                                              as they wish, offering the ability
                                              to conduct unlimited mystery
                                              shops. Options to self organize
                                              evaluations, or access our
                                              qualified shopper database allows
                                              a hotel to create a Mystery
                                              Shopping strategy that actually
                                              works.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          QTX CONNECT
                                        </h5>
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="1899"
                                                height="987"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok.png 1899w, https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok-300x156.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok-1024x532.png 1024w, https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok-768x399.png 768w, https://old.xinperformance.com/wp-content/uploads/2020/06/2-Connect-Dashboard-Ok-1536x798.png 1536w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                        <div className="vc_btn3-container vc_btn3-inline mt-4">
                                          <CustomButton
                                            href="https://old.xinperformance.com/wp-content/uploads/2020/06/Introduction-to-QTX-Connect.pdf"
                                            background="rgb(73, 192, 182)"
                                            color="white"
                                            size="lg"
                                          >
                                            QTX Connect Deck
                                          </CustomButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h5 className="vc_custom_heading">
                                          DYNAMIC GUEST SATISFACTION ANALYSIS
                                        </h5>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              Studies show that a bad survey
                                              experience can negatively impact
                                              overall satisfaction by up to 15%.
                                              We have learned that Guests do not
                                              want to relive their hotel
                                              experience within a survey, even
                                              if it was an exceptional stay. It
                                              just adds unnecessary work.
                                              However, when a Guest is given the
                                              opportunity to take ownership over
                                              their feedback, the quality of
                                              response becomes stronger and the
                                              Guest can actually develop further
                                              loyalty after they have left a
                                              Hotel.{" "}
                                              <strong>
                                                <a href="https://old.xinperformance.com/qtx-connect/">
                                                  QTX Connect
                                                </a>{" "}
                                              </strong>
                                              is a multi-model, real-time survey
                                              platform that invites Guests to
                                              provided targeted feedback in a
                                              concise, timely environment.
                                              Results are instant and
                                              operational teams at all levels
                                              can immediately view analysis,
                                              share or respond to feedback and
                                              take corrective action. Enabling
                                              teams to take ownership over Guest
                                              experiences.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Tab.Pane>
                        <Tab.Pane eventKey="SPATX">
                          <div className="container ourservices-container py-5 ">
                            <div className="row ourservices-row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="aboutbox">
                                  <div className="aboutbox-col aboutbox-img">
                                    <div className="aboutboximg-in">
                                      <img
                                        className="img-responsive"
                                        src="https://www.datocms-assets.com/142763/1729606038-spa_tx-logo-new.jpg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="aboutbox-col aboutbox-text">
                                    <div className="aboutboxtext-in">
                                      <h2>
                                        BUSINESS INTELLIGENCE FOR EVERY SPA
                                      </h2>
                                      <p>
                                        An intuitive booking platform that leads
                                        to 30+ real time Key Performance
                                        Indicators for your spa. Our goal is to
                                        connect spa leaders with decisions that
                                        drive their business to immediate
                                        performance improvement.
                                      </p>
                                      <div className="site-btn">
                                        <CustomButton
                                          href="/products"
                                          background="rgb(73, 192, 182)"
                                          color="white"
                                          size="lg"
                                        >
                                          Request a Demo
                                        </CustomButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <section className="section-row osmiddle-section">
                            <div className="section-inner">
                              <div className="container">
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="512"
                                                height="512"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/04/KPI.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/04/KPI.png 512w, https://old.xinperformance.com/wp-content/uploads/2020/04/KPI-300x300.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/04/KPI-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/04/KPI-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-10">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Real Time KPI’s
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              30+ Real Time KPI’s for every spa
                                              leader to manage their business
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="256"
                                                height="256"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/04/booking.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/04/booking.png 256w, https://old.xinperformance.com/wp-content/uploads/2020/04/booking-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/04/booking-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-10">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Intuitive Booking Engine
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              Clear and comprehensive booking
                                              platform that includes incremental
                                              revenue drivers
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="512"
                                                height="512"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/04/Dashboard.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/04/Dashboard.png 512w, https://old.xinperformance.com/wp-content/uploads/2020/04/Dashboard-300x300.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/04/Dashboard-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/04/Dashboard-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-10">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Live Dashboard
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              A daily KPI pulse check to clearly
                                              understand how to improve the days
                                              performance
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="256"
                                                height="256"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/04/OnlineBooking.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/04/OnlineBooking.png 256w, https://old.xinperformance.com/wp-content/uploads/2020/04/OnlineBooking-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/04/OnlineBooking-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-10">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Online Booking
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              Direct, online booking
                                              capabilities for every spa Guest
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-2">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="256"
                                                height="256"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/04/mobile_app.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/04/mobile_app.png 256w, https://old.xinperformance.com/wp-content/uploads/2020/04/mobile_app-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/04/mobile_app-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-10">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Mobile App
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              IOS and Android Apps for all Hotel
                                              / Spa colleagues to make bookings
                                              and support spa business.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Tab.Pane>
                        <Tab.Pane eventKey="CUSTOM">
                          <div className="container ourservices-container py-5 ">
                            <div className="row ourservices-row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="aboutbox">
                                  <div className="aboutbox-col aboutbox-img">
                                    <div className="aboutboximg-in">
                                      <img
                                        className="img-responsive"
                                        src="https://www.datocms-assets.com/142763/1729607680-custom-solution.png"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="aboutbox-col aboutbox-text">
                                    <div className="aboutboxtext-in">
                                      <h2>
                                        CUSTOM BUILT PERFORMANCE SOLUTIONS
                                      </h2>
                                      <p>
                                        There are often situations where
                                        businesses are not able to utilize the
                                        solutions on the market. Due to security
                                        or operational requirements, the need
                                        for rapid adjustments and improvements
                                        in functionality or perhaps long-term
                                        economical reasons. An ideal solution
                                        may be to custom build a product that
                                        meets the specific needs of your
                                        business and brand. Our specialty is
                                        understanding the exact user experience
                                        required and asking the best possible
                                        questions so that you can create the
                                        product you want for your team. We have
                                        both the experience and the bandwidth to
                                        accomplish this.
                                      </p>
                                      <div className="site-btn">
                                        <CustomButton
                                          href="/products"
                                          background="rgb(73, 192, 182)"
                                          color="white"
                                          size="lg"
                                        >
                                          Contact us today for a consultation
                                        </CustomButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <section className="section-row osmiddle-section">
                            <div className="section-inner">
                              <div className="container">
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="150"
                                                height="150"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/05/Accor_logo_Gold_RVB-150x150.png"
                                                className="vc_single_image-img attachment-thumbnail"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/05/Accor_logo_Gold_RVB-150x150.png 150w, https://old.xinperformance.com/wp-content/uploads/2020/05/Accor_logo_Gold_RVB-100x100.png 100w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-9">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Accor Spalytics
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              A custom built solution for Accor
                                              Hotels &amp; Resorts Worldwide,
                                              featuring real-time tracking of
                                              business KPI’s to enable stronger
                                              communication of performance
                                              strategies between hotel spa
                                              leaders with corporate and
                                              regional stakeholders.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="116"
                                                height="76"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/06/sands_china_log.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-9">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          Gaming Patron Feedback Solution
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              A performance based feedback
                                              system, built for the gaming
                                              industry to assist accurate and
                                              timely feedback from VIP players
                                              to operational leaders. This
                                              resulted in effective
                                              understanding of players’ needs as
                                              well as challenges and recovery
                                              activity.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                          <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                decoding="async"
                                                width="1533"
                                                height="417"
                                                src="https://old.xinperformance.com/wp-content/uploads/2020/06/logo.png"
                                                className="vc_single_image-img attachment-full"
                                                alt=""
                                                srcSet="https://old.xinperformance.com/wp-content/uploads/2020/06/logo.png 1533w, https://old.xinperformance.com/wp-content/uploads/2020/06/logo-300x82.png 300w, https://old.xinperformance.com/wp-content/uploads/2020/06/logo-1024x279.png 1024w, https://old.xinperformance.com/wp-content/uploads/2020/06/logo-768x209.png 768w"
                                                sizes="100vw"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container vc_col-sm-9">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <h2 className="vc_custom_heading">
                                          ESPA
                                        </h2>
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              A global spa operation within
                                              multiple high volume hotels and
                                              cruise ships with a heavy focus on
                                              retail and treatment production
                                              analytics. Xin Performance created
                                              a custom solution to implement
                                              ESPA requriements for system
                                              adoption and data analysis.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
