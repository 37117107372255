import React from "react";
import CustomButton from "../../../../../common/CustomButton";
import { ISolutionCard } from "../../../../../models/blocks/Solutions";
import "./styles.scss";
interface Props {
  solutionCards: ISolutionCard[];
}

export default function SolutionCard({ solutionCards }: Props) {
  return (
    <div className="product-card-container px-4">
      {solutionCards.map((product) => {
        return (
          <div key={product.id} className="product-card pt-4 px-4 bg-light">
            <div
              className="product-card-topImage mt-5 mb-4"
              style={{ backgroundImage: `url(${product.image.url})` }}
            ></div>
            <div>
              <h4 className="text-center px-5 pt-4">{product.title}</h4>

              <p className="text-center px-4 pt-4 fw-light">
                {product.description}
              </p>
            </div>
            <div className="product-card-button-container">
              <CustomButton
                background={product.button.backgroundColor.hex}
                color={product.button.textColor.hex}
                href={product.button.link}
              >
                {product.button.title}
              </CustomButton>
            </div>
          </div>
        );
      })}
    </div>
  );
}
