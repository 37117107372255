import React from "react";
import { useForm } from "react-hook-form";
import "./styles.scss"; // Assume you have custom styles for the form here
import CustomButton from "../../common/CustomButton";

export default function ContactPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <div className="site-content-container px-4">
      <div className="page-wrap py-5">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <div className="form-group">
            <label htmlFor="help">How can we help you?</label>
            <select
              id="help"
              {...register("help", { required: "Please select an option" })}
              className={`form-control ${errors.help ? "is-invalid" : ""}`}
            >
              <option value=""></option>
              <option value="support">Request a demo</option>
              <option value="sales">Media enquire</option>
              <option value="feedback">Others</option>
            </select>
          </div>

          {/* Company Name Field */}
          <div className="form-group">
            <label htmlFor="company">Company Name</label>
            <input
              type="text"
              id="company"
              className="form-control"
              {...register("company", { required: "Company name is required" })}
            />
          </div>

          {/* Name Field */}
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="form-control"
              {...register("name", { required: "Name is required" })}
            />
          </div>

          {/* Email Field */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-control"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
            />
          </div>

          {/* Job Title Field */}
          <div className="form-group">
            <label htmlFor="jobTitle">Job Title</label>
            <input
              type="text"
              id="jobTitle"
              className="form-control"
              {...register("jobTitle", { required: "Job title is required" })}
            />
          </div>

          {/* Message/Comment Field */}
          <div className="form-group">
            <label htmlFor="message">
              Enter any questions or comments here
            </label>
            <textarea
              id="message"
              className="form-control"
              {...register("message", { required: "Message is required" })}
            />
          </div>

          <CustomButton
            href="/products"
            background="rgb(73, 192, 182)"
            color="white"
            size="sm"
          >
            Submit
          </CustomButton>
        </form>
      </div>
    </div>
  );
}
