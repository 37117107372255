import React from "react";
import CustomButton from "../../common/CustomButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { IJumbotron } from "../../models/blocks/Jumbotron";
import "./styles.scss";

interface Props {
  data: IJumbotron;
  isLandingPage: boolean;
}

export default function Jumbotron({ data, isLandingPage }: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isDesktop = useMediaQuery("(min-width: 1500px)");

  return (
    <div className="banner-wrap">
      <div className="banner-inner">
        <img
          className="img-responsive"
          src={data.backgroundImage.url}
          style={{
            ...(isMobile && { height: 300 }),
            ...(isDesktop && { height: 908 }),
            ...(!isLandingPage && { height: 400 }),
          }}
          alt="banner"
        />
      </div>

      <div
        className="banner-text"
        style={{
          ...(isMobile && { top: "64%" }),
        }}
      >
        {isLandingPage && (
          <>
            <h3
              className={`text-light fw-bold ${
                isMobile ? "display-5" : "display-3"
              }`}
            >
              {data.title.toUpperCase().split(" ")[0]}
            </h3>
            <h3
              className={`fw-bold ${isMobile ? "display-5" : "display-3"}`}
              style={{ color: "#49C0B6" }}
            >
              {data.title.toUpperCase().split(" ").splice(1).join(" ")}
            </h3>
          </>
        )}
        {!isLandingPage && (
          <h3 className={`text-light fw-bold display-3`}>
            {data.title.toUpperCase()}
          </h3>
        )}
        <h4
          className="text-light"
          style={{
            width: "60%",
            margin: "auto",
            ...(isMobile && { width: "95%", visibility: "hidden" }),
          }}
        >
          {data.description}
        </h4>
        {isLandingPage && (
          <div
            className="mt-4"
            style={{
              width: "fit-content",
              margin: "auto",
              ...(isMobile && { display: "none" }),
            }}
          >
            {data.button && (
              <CustomButton
                background={data.button.backgroundColor.hex}
                color={data.button.textColor.hex}
                size={data.button.size ? "sm" : "lg"}
              >
                {data.button.title}
              </CustomButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
